import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { ActionNetworkFooterForm } from "./an-footer-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import { responsiveStyling } from "../utils/methods";
import ScreenSizeContext from "../context/ScreenSizeContext";
import { ViewElement } from "./atoms";
import Colours from "../constants/colours";

const styles = {
  innerDynPopup: {
    universal: {
      display: "grid",
      gridGap: "15px",
      gridTemplateColumns: "1fr 1fr",
    },
    mobile: {
      gridTemplateColumns: "1fr",
    },
  },
  dynPopupText: {
    universal: {
      maxWidth: "475px",
      fontFamily: "'IBM Plex Mono', Menlo, monospace",
      color: Colours.gold,
      fontSize: "26px",
    },
    mobile: {
      fontSize: "18px",
    },
  },
  socialPopupWrapper: {
    universal: {
      position: "fixed",
      width: "min(900px, calc(100vw - 60px))",
      maxHeight: "min(800px, calc(100vh - 50px))",
      backgroundColor: Colours.black,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "30px",
      boxShadow: "30px 30px 5px rgba(0, 0, 0, 0.8)",
      zIndex: 9999999,
      color: Colours.white,
      overflow: "hidden",
      border: "1px solid " + Colours.gold,
      fontSize: "min(3vw, 16px)",
    },
    mobile: {
      borderRadius: "20px",
    },
  },
  socialPopupHeading: {
    universal: {
      fontWeight: "normal",
      my: "0.5em",
      lineHeight: 1,
      fontFamily: "'IBM Plex Mono',Menlo,monospace",
      letterSpacing: "-0.7px",
    },
    mobile: {
      letterSpacing: "-0.5px",
    },
  },
};

export const BriefingDynamicPopup: ViewElement = () => {
  const { screenSize } = useContext(ScreenSizeContext);

  const [open, setOpen] = useState(false);

  const [sticky, setSticky] = useState(true);

  const overallHeight = 500;
  const closedHeight = 0;

  useEffect(() => {
    const stickyElement = document.getElementById("briefing-footer");
    let observer: IntersectionObserver | undefined = undefined;

    if (stickyElement) {
      observer = new IntersectionObserver(
        ([e]) => {
          const isSticky = !!(e.intersectionRatio < 1);

          setSticky(isSticky);
        },
        { threshold: [1] }
      );

      observer.observe(stickyElement);
    }

    return () => {
      if (observer && stickyElement) {
        observer.unobserve(stickyElement);
      }
    };
  }, []);

  const localStyles = {
    popupContainer: {
      universal: {
        fontFamily: "'IBM Plex Mono', Menlo, monospace",
        transition: `color 0.3s, max-width ${
          open ? "1" : "2"
        }s, top 0.1s, right 0.1s`,
        cursor: sticky ? "pointer" : "default",
        lineHeight: "40px",
        fontSize: "20px",
        padding: 0,
        paddingLeft: !open ? "15px" : 0,
        paddingRight: !open ? "40px" : 0,
        backgroundColor: Colours.black,
        borderRadius: "50px",
        color: open ? Colours.black : Colours.gold,
        position: "absolute",
        top: open ? "-45px" : "-65px",
        right: open ? "5px" : "25px",
        height: "40px",
        minWidth: "40px",
        maxWidth: open ? "40px" : "400px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        border: "1px solid " + Colours.gold,
      },
      mobile: {
        top: "-45px",
        right: "5px",
      },
      tablet: {
        top: open ? "-45px" : "-55px",
        right: open ? "5px" : "15px",
      },
      restricted: {},
      full: {},
    },
  };

  return (
    <div
      style={{
        transition: "max-height 0.2s",
        backgroundColor: Colours.black,
        position: "absolute",
        bottom: 0,
        width: "100%",
        zIndex: 10,
        maxHeight: open || !sticky ? overallHeight + "px" : closedHeight + "px",
        minHeight: closedHeight + "px",
      }}
    >
      {sticky && (
        <div
          onClick={() => setOpen(!open)}
          style={responsiveStyling(localStyles.popupContainer, screenSize)}
        >
          {!open && <Trans>Receive the RI Briefing</Trans>}
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "25px",
              width: "25px",
              textAlign: "center",
              fontSize: "25px",
              lineHeight: "25px",
              transition: "transform 0.5s",
              transform: open || !sticky ? "scale(1, -1)" : "scale(1, 1)",
              color: Colours.gold,
              padding: "7.5px",
              backgroundColor: Colours.black,
            }}
          >
            {(open || !sticky) && <FontAwesomeIcon icon={faXmark} />}
            {!(open || !sticky) && <FontAwesomeIcon icon={faChevronUp} />}
          </div>
        </div>
      )}
      <div style={{ padding: "25px", position: "relative" }}>
        <div style={responsiveStyling(styles.innerDynPopup, screenSize)}>
          <div style={responsiveStyling(styles.dynPopupText, screenSize)}>
            <Trans>Receive the Reactionary International briefing</Trans>
          </div>
          <ActionNetworkFooterForm popup={false} />
        </div>
      </div>
    </div>
  );
};

const POPUP_KEY = "ri_popup_flag";

export const SocialPopup: ViewElement = ({}) => {
  const { screenSize } = useContext(ScreenSizeContext);

  const [popped, setPopped] = useState(true);

  useEffect(() => {
    if (window && !localStorage.getItem(POPUP_KEY)) {
      setPopped(false);
    }
  }, []);

  const closeFunction = () => {
    localStorage.setItem(POPUP_KEY, "true");
    setPopped(true);
  };

  if (popped) {
    return null;
  } else {
    return (
      <div style={responsiveStyling(styles.socialPopupWrapper, screenSize)}>
        <div
          style={{
            position: "absolute",
            top: "7px",
            right: "7px",
            width: "33px",
            height: "33px",
            backgroundColor: Colours.gold,
            cursor: "pointer",
            borderRadius: "100px",
          }}
          onClick={() => closeFunction()}
        >
          <FontAwesomeIcon
            style={{
              color: Colours.black,
              width: "25px",
              height: "25px",
              margin: "4px 2px 4px 4px",
            }}
            icon={faXmark}
          />
        </div>
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "min(800px,calc(100vh - 72px))",
            padding: "45px 25px 30px",
          }}
          className={"scrollbar-styling"}
        >
          <h1
            style={{
              ...responsiveStyling(styles.socialPopupHeading, screenSize),
              ...{ marginTop: 0, color: Colours.gold },
            }}
          >
            <Trans>
              Welcome to the Reactionary International Research Consortium
            </Trans>
          </h1>
          <h2 style={responsiveStyling(styles.socialPopupHeading, screenSize)}>
            <Trans>
              Sign up now to receive breaking news and important updates about
              far-right forces across the world.
            </Trans>
            <span
              style={{
                fontSize: 11,
                fontFamily:
                  "'neue-haas-grotesk-display','Neue Haas','Helvetica Neue','Helvetica',system-ui,sans-serif",
                fontWeight: "bold",
                letterSpacing: "0.5px",
                marginLeft: "2.5px",
              }}
            >
              <Trans>We protect your data and never send spam.</Trans>
            </span>
          </h2>
          <ActionNetworkFooterForm finishFunction={closeFunction} />
        </div>
      </div>
    );
  }
};
