import { FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Fragment,
  DetailedHTMLProps,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";
import * as yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import React from "react";

const styles = {
  error: {
    color: "red",
  },
  checkboxOption: {},
  checkbox: {},
  label: {},
};

type YupObjectSchema = yup.ObjectSchema<{ [key: string]: any }>;

const RequiredLabel = () => <span style={{ color: "red" }}>&nbsp;*</span>;

export function CheckboxInputArray<S extends YupObjectSchema>({
  children,
  name,
  schema,
  errors,
  register,
  labels,
  ...componentProps
}: {
  name: keyof S["fields"];
  errors: FieldErrors;
  schema: S;
  register: any;
  labels: string[];
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  const field = schema.fields[name];
  const hasErrors = errors[name];
  const errorMessage = (
    <Trans>
      <ErrorMessage errors={errors} name={name} />
    </Trans>
  );

  return (
    <Fragment>
      {errors && hasErrors && <div style={styles.error}>{errorMessage}</div>}
      {labels.map((l, index) => {
        return (
          <div style={styles.checkboxOption}>
            <input
              style={styles.checkbox}
              type="checkbox"
              name={name + `[${index.toString()}]`}
              ref={register}
            />
            {l}
            {/*field?._exclusive?.required && <RequiredLabel />*/}
          </div>
        );
      })}
    </Fragment>
  );
}

export function CheckboxInput<S extends YupObjectSchema>({
  children,
  name,
  label,
  schema,
  errors,
  register,
  ...componentProps
}: {
  label?: string;
  name: keyof S["fields"];
  errors: FieldErrors;
  schema: S;
  register: any;
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  const field = schema.fields[name];
  const _label = label || field?._label;
  const hasErrors = errors[name];
  const errorMessage = (
    <Trans>
      <ErrorMessage errors={errors} name={name} />
    </Trans>
  );

  return (
    <Fragment>
      {errors && hasErrors && <div style={styles.error}>{errorMessage}</div>}
      <div style={{ ...styles.checkboxOption, ...{ marginTop: "0px" } }}>
        <input
          style={styles.checkbox}
          type="checkbox"
          name={name}
          ref={register}
        />
        <Trans>{children || _label}</Trans>
        {field?._exclusive?.required && <RequiredLabel />}
      </div>
    </Fragment>
  );
}

export function TextInput<S extends YupObjectSchema>({
  name,
  label,
  schema,
  errors,
  register,
  showLabel = true,
  showErrors = true,
  labelStyle,
  inputStyle,
  ...componentProps
}: {
  label?: string;
  showLabel: boolean;
  name: keyof S["fields"];
  errors: FieldErrors;
  schema: S;
  register: any;
  showErrors: boolean;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  try {
    const id = `field-${name}`;
    const field = schema.fields[name];
    const _label = label || field?._label;
    const hasErrors = errors ? !!errors[name] : false;
    const errorMessage = (
      <Trans>
        <ErrorMessage errors={errors} name={name} />
      </Trans>
    );

    return (
      <Fragment>
        {showLabel && _label && (
          <div style={labelStyle}>
            <Trans>{_label}</Trans>
            {field?._exclusive?.required && <RequiredLabel />}
          </div>
        )}
        {showErrors && errors && hasErrors && (
          <div style={styles.error}>{errorMessage}</div>
        )}
        <input
          style={inputStyle}
          type="text"
          {...componentProps}
          id={id}
          {...register(name)}
        />
      </Fragment>
    );
  } catch (e) {
    throw new Error(`${name} field probably does not exist in the Yup schema.`);
  }
}

export function TextAreaInput<S extends YupObjectSchema>({
  name,
  label,
  schema,
  errors,
  register,
  ...componentProps
}: {
  label?: string;
  name: keyof S["fields"];
  errors: FieldErrors;
  schema: S;
  register: any;
} & DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>) {
  try {
    const id = `field-${name}`;
    const field = schema.fields[name];
    const _label = label || field?._label;
    const hasErrors = errors[name];
    const errorMessage = (
      <Trans>
        <ErrorMessage errors={errors} name={name} />
      </Trans>
    );

    return (
      <Fragment>
        {_label && (
          <div>
            <Trans>{_label}</Trans>
            {field?._exclusive?.required && <RequiredLabel />}
          </div>
        )}
        {errors && hasErrors && <div style={styles.error}>{errorMessage}</div>}
        <textarea {...componentProps} name={name} id={id} ref={register} />
      </Fragment>
    );
  } catch (e) {
    console.error(name, schema.fields);
    throw new Error(`${name} field probably does not exist in the Yup schema.`);
  }
}

export function SelectInput<O, S extends YupObjectSchema>({
  name,
  label,
  options,
  errors,
  renderOption,
  schema,
  register,
  ...inputProps
}: {
  label?: string;
  name: keyof S["fields"];
  errors: FieldErrors;
  options: O[];
  renderOption: (i: O, ind: number, arr: O[]) => any;
  schema: S;
  register: any;
  inverted?: boolean;
} & DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>) {
  const { t } = useTranslation();
  try {
    const id = `field-${name}`;
    const field = schema.fields[name];
    const _label = label || field?._label;
    const hasErrors = errors[name];
    const errorMessage = (
      <Trans>
        <ErrorMessage errors={errors} name={name} />
      </Trans>
    );

    return (
      <Fragment>
        {_label && (
          <div style={styles.label}>
            <Trans>{_label}</Trans>
            {field?._exclusive?.required && <RequiredLabel />}
          </div>
        )}
        {errors && hasErrors && <div style={styles.error}>{errorMessage}</div>}
        <select {...inputProps} name={name} id={id} ref={register}>
          <option selected value={undefined} disabled>
            {t(`Pick an option`)}
          </option>
          {options.map(renderOption)}
        </select>
      </Fragment>
    );
  } catch (e) {
    console.error(name, schema.fields);
    throw new Error(`${name} field probably does not exist in the Yup schema.`);
  }
}
