import { NodeContent } from "../data/content";
import { ScreenSize } from "./hooks";

export const hasTags = (node: NodeContent, tags: string[]) => {
  return (
    node.frontmatter.relatedTags &&
    node.frontmatter.relatedTags.filter((rt) =>
      tags.includes(rt.frontmatter.title)
    ).length > 0
  );
};

export const responsiveStyling = (stylingObj: any, screenSize: ScreenSize) => {
  if (stylingObj[screenSize]) {
    return { ...stylingObj.universal, ...stylingObj[screenSize] };
  } else {
    return stylingObj.universal;
  }
};

export const titleTrim = (title: string, amount: number = 50) => {
  let shortenedTitle = "";
  if (title.length > amount) {
    shortenedTitle = title.substring(0, amount) + "...";
  } else {
    shortenedTitle = (" " + title).slice(1);
  }

  return shortenedTitle;
};

export const removeAttributeFromClass = (
  targetClass: string,
  attribute: string
) => {
  const classElements = document.getElementsByClassName(targetClass);

  for (let i = 0; i < classElements.length; i++) {
    classElements[i].removeAttribute(attribute);
  }
};

export const setFirstNodeMargin = () => {
  const mainText = document.getElementById("mainText");

  if (mainText && mainText.childNodes.length > 0) {
    const firstNode = mainText.childNodes[0];

    firstNode.style.marginTop = "-0.3em";
  }
};

export const replaceNamesWithLinks = (
  relatedProfiles: NodeContent[] | undefined
) => {
  if (!relatedProfiles) {
    return;
  }

  const profiles = [];

  for (let i = 0; i < relatedProfiles.length; i++) {
    profiles.push({
      title: relatedProfiles[i].frontmatter.title.trim(),
      link: relatedProfiles[i].fields.path + "/" + relatedProfiles[i].locale,
    });
  }

  const relevantNodes = [];

  const mainText = document.getElementById("mainText");

  if (!mainText) {
    return;
  }

  const walker = document.createTreeWalker(mainText, 4);

  while (walker.nextNode()) {
    if (
      walker.currentNode.parentNode?.nodeName.toLowerCase() == "p" &&
      !relevantNodes.includes(walker.currentNode.parentNode) &&
      walker.currentNode.nodeName.toLowerCase() != "span"
    ) {
      relevantNodes.push(walker.currentNode.parentNode);
    }
  }

  for (let i = 0; i < profiles.length; i++) {
    for (let i2 = 0; i2 < relevantNodes.length; i2++) {
      if (relevantNodes[i2].innerHTML.includes(profiles[i].title)) {
        const tempInnerHTMLArray = relevantNodes[i2].innerHTML.split(
          profiles[i].title
        );
        let tempInnerHTMLRes = "";
        for (let i3 = 0; i3 < tempInnerHTMLArray.length; i3++) {
          if (i3 > 0) {
            const profileLink = profiles[i].link.startsWith("/")
              ? profiles[i].link.substring(1)
              : profiles[i].link;
            const tempLink = `<a href="https://reactionary.international/${profileLink}">${profiles[i].title}</a>`;
            tempInnerHTMLRes += tempLink;
          }
          tempInnerHTMLRes += tempInnerHTMLArray[i3];
        }
        relevantNodes[i2].innerHTML = tempInnerHTMLRes;
      }
    }
  }
};