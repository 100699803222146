export default [
  {
    name: "English",
    isoName: "en",
    nativeName: "English",
  },
  {
    name: "Afrikaans",
    isoName: "af",
    nativeName: "Afrikaans",
  },
  {
    name: "Bosnian",
    isoName: "bs",
    nativeName: "Bosanski",
  },
  {
    name: "Welsh",
    isoName: "cy",
    nativeName: "Cymraeg",
  },
  {
    name: "Xhosa",
    isoName: "xh",
    nativeName: "isiXhosa",
  },
  {
    name: "Yiddish",
    isoName: "yi",
    nativeName: "ייִדיש",
  },
  {
    name: "Yoruba",
    isoName: "yo",
    nativeName: "Yorùbá",
  },
  {
    name: "Zulu",
    isoName: "zu",
    nativeName: "isiZulu",
  },
  {
    name: "Bulgarian",
    isoName: "bg",
    nativeName: "Български",
  },
  {
    name: "Catalan",
    isoName: "ca",
    nativeName: "Català",
  },
  {
    name: "Cebuano",
    isoName: "ceb",
    nativeName: "Bisaya",
  },
  {
    name: "Chinese (Simplified)",
    isoName: "zh-CN",
    nativeName: "中文 (中国)",
  },
  {
    name: "Chinese (Traditional)",
    isoName: "zh-TW",
    nativeName: "中文 (臺灣)",
  },
  {
    name: "Corsican",
    isoName: "co",
    nativeName: "Corsu",
  },
  {
    name: "Croatian",
    isoName: "hr",
    nativeName: "Hrvatski",
  },
  {
    name: "Czech",
    isoName: "cs",
    nativeName: "Česky",
  },
  {
    name: "Danish",
    isoName: "da",
    nativeName: "Dansk",
  },
  {
    name: "Albanian",
    isoName: "sq",
    nativeName: "Shqip",
  },
  {
    name: "Dutch",
    isoName: "nl",
    nativeName: "Nederlands",
  },
  {
    name: "Esperanto",
    isoName: "eo",
    nativeName: "Esperanto",
  },
  {
    name: "Estonian",
    isoName: "et",
    nativeName: "Eesti",
  },
  {
    name: "Finnish",
    isoName: "fi",
    nativeName: "Suomi",
  },
  {
    name: "French",
    isoName: "fr",
    nativeName: "Français",
  },
  {
    name: "Frisian",
    isoName: "fy",
    nativeName: "Frysk",
  },
  {
    name: "Galician",
    isoName: "gl",
    nativeName: "Galego",
  },
  {
    name: "Georgian",
    isoName: "ka",
    nativeName: "ქართული",
  },
  {
    name: "German",
    isoName: "de",
    nativeName: "Deutsch",
  },
  {
    name: "Amharic",
    isoName: "am",
    nativeName: "አማርኛ",
  },
  {
    name: "Greek",
    isoName: "el",
    nativeName: "Ελληνικά",
  },
  {
    name: "Gujarati",
    isoName: "gu",
    nativeName: "ગુજરાતી",
  },
  {
    name: "Haitian Creole",
    isoName: "ht",
    nativeName: "Krèyol ayisyen",
  },
  {
    name: "Hausa",
    isoName: "ha",
    nativeName: "هَوُسَ",
  },
  {
    name: "Hawaiian",
    isoName: "haw",
    nativeName: "Ōlelo Hawaiʻi,",
  },
  {
    name: "Hebrew",
    isoName: "he",
    nativeName: "עִבְרִית‎",
  },
  {
    name: "Hindi",
    isoName: "hi",
    nativeName: "हिन्दी",
  },
  {
    name: "Hmong",
    isoName: "hmn",
    nativeName: "Hmong",
  },
  {
    name: "Hungarian",
    isoName: "hu",
    nativeName: "Magyar",
  },
  {
    name: "Icelandic",
    isoName: "is",
    nativeName: "Íslenska",
  },
  {
    name: "Arabic",
    isoName: "ar",
    nativeName: "العربية",
  },
  {
    name: "Igbo",
    isoName: "ig",
    nativeName: "Igbo",
  },
  {
    name: "Indonesian",
    isoName: "id",
    nativeName: "Bahasa Indonesia",
  },
  {
    name: "Irish",
    isoName: "ga",
    nativeName: "Gaeilge",
  },
  {
    name: "Italian",
    isoName: "it",
    nativeName: "Italiano",
  },
  {
    name: "Japanese",
    isoName: "ja",
    nativeName: "日本語",
  },
  {
    name: "Javanese",
    isoName: "jw",
    nativeName: "ꦧꦱꦗꦮ",
  },
  {
    name: "Kannada",
    isoName: "kn",
    nativeName: "ಕನ್ನಡ",
  },
  {
    name: "Kazakh",
    isoName: "kk",
    nativeName: "Қазақша",
  },
  {
    name: "Khmer",
    isoName: "km",
    nativeName: "ភាសាខ្មែរ",
  },
  {
    name: "Korean",
    isoName: "ko",
    nativeName: "한국어",
  },
  {
    name: "Armenian",
    isoName: "hy",
    nativeName: "Հայերեն",
  },
  {
    name: "Kurdish",
    isoName: "ku",
    nativeName: "Kurdî / كوردی",
  },
  {
    name: "Kyrgyz",
    isoName: "ky",
    nativeName: "Kırgızca / Кыргызча",
  },
  {
    name: "Lao",
    isoName: "lo",
    nativeName: "ລາວ / Pha xa lao",
  },
  {
    name: "Latin",
    isoName: "la",
    nativeName: "Latina",
  },
  {
    name: "Latvian",
    isoName: "lv",
    nativeName: "Latviešu",
  },
  {
    name: "Lithuanian",
    isoName: "lt",
    nativeName: "Lietuvių",
  },
  {
    name: "Luxembourgish",
    isoName: "lb",
    nativeName: "Lëtzebuergesch",
  },
  {
    name: "Macedonian",
    isoName: "mk",
    nativeName: "Македонски",
  },
  {
    name: "Malagasy",
    isoName: "mg",
    nativeName: "Malagasy",
  },
  {
    name: "Malay",
    isoName: "ms",
    nativeName: "Bahasa Melayu",
  },
  {
    name: "Azerbaijani",
    isoName: "az",
    nativeName: "Azərbaycanca / آذربايجان",
  },
  {
    name: "Malayalam",
    isoName: "ml",
    nativeName: "മലയാളം",
  },
  {
    name: "Maltese",
    isoName: "mt",
    nativeName: "bil-Malti",
  },
  {
    name: "Maori",
    isoName: "mi",
    nativeName: "Māori",
  },
  {
    name: "Marathi",
    isoName: "mr",
    nativeName: "मराठी",
  },
  {
    name: "Mongolian",
    isoName: "mn",
    nativeName: "Монгол",
  },
  {
    name: "Myanmar (Burmese)",
    isoName: "my",
    nativeName: "Myanmasa",
  },
  {
    name: "Nepali",
    isoName: "ne",
    nativeName: "नेपाली",
  },
  {
    name: "Norwegian",
    isoName: "no",
    nativeName: "Norsk (bokmål / nynorsk)",
  },
  {
    name: "Nyanja (Chichewa)",
    isoName: "ny",
    nativeName: "Chi-Chewa",
  },
  {
    name: "Pashto",
    isoName: "ps",
    nativeName: "پښتو",
  },
  {
    name: "Basque",
    isoName: "eu",
    nativeName: "Euskara",
  },
  {
    name: "Persian",
    isoName: "fa",
    nativeName: "فارسی",
  },
  {
    name: "Polish",
    isoName: "pl",
    nativeName: "Polski",
  },
  {
    name: "Portuguese (Portugal)",
    isoName: "pt",
    nativeName: "Português (Portugal)",
  },
  {
    name: "Portuguese (Brazil)",
    isoName: "pt-br",
    nativeName: "Português (Brasil)",
  },
  {
    name: "Punjabi",
    isoName: "pa",
    nativeName: "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
  },
  {
    name: "Romanian",
    isoName: "ro",
    nativeName: "Română",
  },
  {
    name: "Russian",
    isoName: "ru",
    nativeName: "Русский",
  },
  {
    name: "Samoan",
    isoName: "sm",
    nativeName: "Gagana Samoa",
  },
  {
    name: "Scots Gaelic",
    isoName: "gd",
    nativeName: "Gàidhlig",
  },
  {
    name: "Serbian",
    isoName: "sr",
    nativeName: "Српски",
  },
  {
    name: "Sesotho",
    isoName: "st",
    nativeName: "Sesotho",
  },
  {
    name: "Belarusian",
    isoName: "be",
    nativeName: "Беларуская",
  },
  {
    name: "Shona",
    isoName: "sn",
    nativeName: "chiShona",
  },
  {
    name: "Sindhi",
    isoName: "sd",
    nativeName: "सिनधि",
  },
  {
    name: "Sinhala (Sinhalese)",
    isoName: "si",
    nativeName: "සිංහල",
  },
  {
    name: "Slovak",
    isoName: "sk",
    nativeName: "Slovenčina",
  },
  {
    name: "Slovenian",
    isoName: "sl",
    nativeName: "Slovenščina",
  },
  {
    name: "Somali",
    isoName: "so",
    nativeName: "Soomaaliga",
  },
  {
    name: "Spanish",
    isoName: "es",
    nativeName: "Español",
  },
  {
    name: "Sundanese",
    isoName: "su",
    nativeName: "Basa Sunda",
  },
  {
    name: "Swahili",
    isoName: "sw",
    nativeName: "Kiswahili",
  },
  {
    name: "Swedish",
    isoName: "sv",
    nativeName: "Svenska",
  },
  {
    name: "Bengali",
    isoName: "bn",
    nativeName: "বাংলা",
  },
  {
    name: "Tagalog (Filipino)",
    isoName: "tl",
    nativeName: "Tagalog",
  },
  {
    name: "Tajik",
    isoName: "tg",
    nativeName: "Тоҷикӣ",
  },
  {
    name: "Tamil",
    isoName: "ta",
    nativeName: "தமிழ்",
  },
  {
    name: "Telugu",
    isoName: "te",
    nativeName: "తెలుగు",
  },
  {
    name: "Thai",
    isoName: "th",
    nativeName: "ไทย / Phasa Thai",
  },
  {
    name: "Tigrinya",
    isoName: "ti",
    nativeName: "ትግርኛ",
  },
  {
    name: "Turkish",
    isoName: "tr",
    nativeName: "Türkçe",
  },
  {
    name: "Ukrainian",
    isoName: "uk",
    nativeName: "Українська",
  },
  {
    name: "Urdu",
    isoName: "ur",
    nativeName: "اردو",
  },
  {
    name: "Uzbek",
    isoName: "uz",
    nativeName: "Ўзбек",
  },
  {
    name: "Vietnamese",
    isoName: "vi",
    nativeName: "Tiếng Việt",
  },
];

export const supportedLanguages = {
  af: "Afrikaans",
  sq: "Albanian",
  ar: "Arabic",
  "ar-dz": "Arabic (Algeria)",
  "ar-bh": "Arabic (Bahrain) ",
  "ar-eg": "Arabic (Egypt)",
  "ar-iq": "Arabic (Iraq)",
  "ar-jo": "Arabic (Jordan)",
  "ar-kw": "Arabic (Kuwait)",
  "ar-lb": "Arabic (Lebanon)",
  "ar-ly": "Arabic (Libya)",
  "ar-ma": "Arabic (Morocco)",
  "ar-om": "Arabic (Oman)",
  "ar-qa": "Arabic (Qatar)",
  "ar-sa": "Arabic (Saudi Arabia)",
  "ar-sy": "Arabic (Syria)",
  "ar-tn": "Arabic (Tunisia)",
  "ar-ae": "Arabic (U.A.E.)",
  "ar-ye": "Arabic (Yemen)",
  eu: "Basque",
  be: "Belarusian",
  bg: "Bulgarian",
  bn: "Bengali",
  ca: "Catalan",
  "zh-hk": "Chinese (Hong Kong)",
  "zh-cn": "Chinese (PRC)",
  "zh-sg": "Chinese (Singapore)",
  "zh-tw": "Chinese (Taiwan)",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  "nl-be": "Dutch (Belgium)",
  nl: "Dutch (Standard)",
  en: "English",
  "en-au": "English (Australia)",
  "en-bz": "English (Belize)",
  "en-ca": "English (Canada)",
  "en-ie": "English (Ireland)",
  "en-jm": "English (Jamaica)",
  "en-nz": "English (New Zealand)",
  "en-za": "English (South Africa)",
  "en-tt": "English (Trinidad)",
  "en-gb": "English (United Kingdom)",
  "en-us": "English (United States)",
  et: "Estonian",
  fo: "Faeroese",
  fa: "Farsi",
  fi: "Finnish",
  fr: "French",
  "fr-be": "French (Belgium)",
  "fr-ca": "French (Canada)",
  "fr-lu": "French (Luxembourg)",
  "fr-ch": "French (Switzerland)",
  gd: "Gaelic (Scotland)",
  "de-at": "German (Austria)",
  "de-li": "German (Liechtenstein)",
  "de-lu": "German (Luxembourg)",
  de: "German",
  "de-ch": "German (Switzerland)",
  el: "Greek",
  he: "Hebrew",
  hi: "Hindi",
  hy: "Armenian",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  ga: "Irish",
  it: "Italian (Standard)",
  "it-ch": "Italian (Switzerland)",
  ja: "Japanese",
  ko: "Korean",
  ku: "Kurdish",
  lv: "Latvian",
  lt: "Lithuanian",
  mk: "Macedonian (FYROM)",
  ml: "Malayalam",
  ms: "Malaysian",
  mt: "Maltese",
  no: "Norwegian",
  nb: "Norwegian (Bokmål)",
  nn: "Norwegian (Nynorsk)",
  pl: "Polish",
  "pt-br": "Portuguese (Brazil)",
  pt: "Portuguese (Portugal)",
  pa: "Punjabi",
  rm: "Rhaeto-Romanic",
  ro: "Romanian",
  "ro-md": "Romanian (Republic of Moldova)",
  ru: "Russian",
  "ru-md": "Russian (Republic of Moldova)",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  sb: "Sorbian",
  es: "Spanish",
  "es-ar": "Spanish (Argentina)",
  "es-bo": "Spanish (Bolivia)",
  "es-cl": "Spanish (Chile)",
  "es-co": "Spanish (Colombia)",
  "es-cr": "Spanish (Costa Rica)",
  "es-do": "Spanish (Dominican Republic)",
  "es-ec": "Spanish (Ecuador)",
  "es-sv": "Spanish (El Salvador)",
  "es-gt": "Spanish (Guatemala)",
  "es-hn": "Spanish (Honduras)",
  "es-mx": "Spanish (Mexico)",
  "es-ni": "Spanish (Nicaragua)",
  "es-pa": "Spanish (Panama)",
  "es-py": "Spanish (Paraguay)",
  "es-pe": "Spanish (Peru)",
  "es-pr": "Spanish (Puerto Rico)",
  "es-uy": "Spanish (Uruguay)",
  "es-ve": "Spanish (Venezuela)",
  sw: "Swahili",
  sv: "Swedish",
  "sv-fi": "Swedish (Finland)",
  ta: "Tamil",
  th: "Thai",
  ti: "Tigrinya",
  ts: "Tsonga",
  tn: "Tswana",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  ve: "Venda",
  vi: "Vietnamese",
  cy: "Welsh",
  xh: "Xhosa",
  ji: "Yiddish",
  zu: "Zulu",
};

export const getLanguageName = (code: keyof typeof supportedLanguages) => {
  try {
    const key = code.toLowerCase();
    return (supportedLanguages as any)[key as any];
  } catch (e) {
    return;
  }
};
