import * as yup from "yup";

export const interestTagFields = {
  INTEREST_TAGS: yup.array().of(yup.boolean()),
};

export const emailFields = {
  EMAIL: yup.string().label("Email").required().email(),
};

export const personalFields = {
  FNAME: yup.string().label("First name").required().min(2).max(150),
  LNAME: yup
    .string()
    .label("Last name")
    .test(
      "length-or-empty",
      "Last name cannot be less than 2 characters.",
      (val) => {
        if (val == null) {
          return false;
        }
        return val.length == 0 || (val.length >= 2 && val.length <= 150);
      }
    ),
};

export const langFields = (options: string[]) => {
  return {
    LANGUAGE: yup
      .string()
      .label("Preferred language")
      .oneOf(options, "Please select a language")
      .required(),
  };
};

export const candleJarContactFields = {
  candle_jar_contact: yup.string().label("Anti-Spam"),
};
