import { IGatsbyImageData } from "gatsby-plugin-image";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getLanguageName } from "./languages";
import { merge } from "lodash";
import { PageProps } from "gatsby";
import { Footnote } from "../components/default";

export type SectionHeaders = {
  id: string;
  translations: {
    newsSectionHeader: string;
    casesSectionHeader: string;
    databaseSectionHeader: string;
    resourcesSectionHeader: string;
    locale: string;
  }[];
};

export type Node = { id: string };

export type NodeContent = {
  id: string;
  fields: {
    slug: string;
    path: string;
    translations: any;
    localePaths: {
      locale: string;
      path: string;
    }[];
  };
  frontmatter: {
    title: string;
    footnotes: Footnote[];
    toc: boolean;
    publishDate: string;
    link: string;
    originalURL: string;
    featuredImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    relatedProfiles: NodeContent[];
    relatedCases: NodeContent[];
    relatedTags: NodeContent[];
    relatedThemes: NodeContent[];
    relatedRegions: NodeContent[];
    source: NodeContent;
    author: NodeContent[];
  };
  html: string;
};

export type Edge<T extends Node> = { node: T };

export type Connection<T extends Node> = { edges: Edge<T>[] };

export type PageRoot<Data> = FC<
  PageProps<Data> & {
    pageContext: {
      locale: string;
      locales: {
        locale: string;
        path: string;
      }[];
    };
  }
>;

type LocaleResult = {
  locale?: string;
  result: "exact" | "variant" | "nomatch";
};

export const lenientlyMatchedLocale = (
  searchLocale: string = "",
  localeOptions: string[] = []
): LocaleResult => {
  // See if there is a translation for this
  let res: LocaleResult = {
    locale: localeOptions?.find(
      (t) => t.toLowerCase() === searchLocale.toLowerCase()
    ),
    result: "exact",
  };
  // Else, see if there is a translation in a different variant of the same language
  if (!res.locale) {
    try {
      res = {
        locale: localeOptions?.find(
          (t) =>
            t.toLowerCase().split("-")[0] ===
            searchLocale.toLowerCase().split("-")[0]
        ),
        result: "variant",
      };
    } catch (e) {
      // }
    }
  }

  // Else, if there are any other translations, use those because it's important for there to be some content
  if (!res.locale) {
    res = {
      locale: localeOptions?.[0]?.toLowerCase(),
      result: "nomatch",
    };
  }
  return res;
};

export const useLocalisedSectionHeaders = (
  node: SectionHeaders,
  defaultLocale?: string
) => {
  // Get user's preferred language
  const {
    i18n: { language },
  } = useTranslation();

  const res = useMemo(() => {
    if (!node) return node;

    // See if there is any (ideally exact) localised copy for this
    const availableLocales: string[] =
      node.translations?.map((t) => t.locale) || [];
    const foundTranslationLocale = lenientlyMatchedLocale(
      defaultLocale || language,
      availableLocales
    );
    const locale = foundTranslationLocale.locale || defaultLocale || language;
    const languageName = getLanguageName(locale as any);
    const contentForLocale = node.translations?.find(
      (t) => t.locale === locale
    );

    // If possible, show localised properties, and fallback to the node's stuff.
    return merge({}, node, contentForLocale, {
      locale,
      languageName,
    });
  }, [node, defaultLocale, language]);

  return res;
};

export const useLocalisedContent = (
  node: NodeContent,
  defaultLocale?: string
): {
  title: string;
  abstract?: string;
  shortAbstract?: string;
  path: string;
  html: string;
  locale: string;
  languageName?: string;
  locales?: Array<{
    locale: string;
    path: string;
  }>;
} => {
  // Get user's preferred language
  const {
    i18n: { language },
  } = useTranslation();

  const res = useMemo(() => {
    if (!node) return node;

    // See if there is any (ideally exact) localised copy for this
    const availableLocales: string[] =
      node.fields.translations?.map(
        (t) => t.childMarkdownRemark?.frontmatter?.locale
      ) || [];
    const foundTranslationLocale = lenientlyMatchedLocale(
      defaultLocale || language,
      availableLocales
    );
    const locale = foundTranslationLocale.locale || defaultLocale || language;
    const languageName = getLanguageName(locale as any);
    const contentForLocale = node.fields.translations?.find(
      (t) => t.childMarkdownRemark.frontmatter.locale === locale
    )?.childMarkdownRemark;
    // Find the canonical link for any translated content
    const linkForLocalisedContent = node.fields?.localePaths?.find(
      (t) => locale === t.locale
    )?.path;
    // If possible, show localised properties, and fallback to the node's stuff.
    return merge(
      {},
      node,
      node?.frontmatter,
      contentForLocale,
      contentForLocale?.frontmatter,
      {
        path: linkForLocalisedContent || node,
        locale,
        languageName,
        locales: node.fields?.localePaths,
      }
    );
  }, [node, defaultLocale, language]);

  return res;
};

export const useLocalisedContentArray = (
  nodes: NodeContent[] | undefined,
  defaultLocale?: string
) => {
  // Get user's preferred language
  const {
    i18n: { language },
  } = useTranslation();

  const res = useMemo(() => {
    if (!nodes || nodes.length < 1) return nodes;

    let finalResults: NodeContent[] = [];

    nodes.forEach((node) => {
      if (node) {
        const availableLocales: string[] =
          node.fields.translations?.map(
            (t) => t.childMarkdownRemark?.frontmatter?.locale
          ) || [];

        const foundTranslationLocale = lenientlyMatchedLocale(
          defaultLocale || language,
          availableLocales
        );
        const locale =
          foundTranslationLocale.locale || defaultLocale || language;
        const languageName = getLanguageName(locale as any);
        const contentForLocale = node.fields.translations?.find(
          (t) => t.childMarkdownRemark.frontmatter.locale === locale
        )?.childMarkdownRemark;

        finalResults.push(
          merge(
            {},
            node,
            node?.frontmatter,
            contentForLocale,
            contentForLocale?.frontmatter,
            {
              locale,
              languageName,
            }
          )
        );
      }
    });

    return finalResults;
  }, [nodes, defaultLocale, language]);

  return res;
};
