import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ViewElement } from "./atoms";
import * as yup from "yup";
import {
  candleJarContactFields,
  emailFields,
  interestTagFields,
  langFields,
  personalFields,
} from "../data/forms";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { Trans } from "react-i18next";
import {
  CheckboxInputArray,
  SelectInput,
  TextInput,
} from "./joinform/elements";
import languages from "../data/languages";
import { NodeContent, useLocalisedContent } from "../data/content";
import ScreenSizeContext from "../context/ScreenSizeContext";
import { responsiveStyling } from "../utils/methods";
import Colours from "../constants/colours";

const styles = {
  emailStepGrid: {
    universal: {
      maxWidth: "100%",
      fontSize: 24,
      display: "grid",
      gap: "25px",
      gridTemplateColumns: "1fr 1fr",
      position: "relative",
      zIndex: 100,
    },
    mobile: {
      fontSize: 18,
      gridTemplateColumns: "1fr",
    },
    tablet: {
      fontSize: 18,
      gridTemplateColumns: "1fr",
    },
  },
  textInput: {
    universal: {
      appearance: "none",
      color: Colours.gold,
      fontFamily: "'IBM Plex Mono', Menlo, monospace",
      backgroundColor: "transparent",
      borderRadius: 0,
      border: "none",
      borderBottom: "2px solid " + Colours.gold,
      width: "100%",
      outline: "none",
      paddingTop: "0.5em",
      paddingBottom: "0.5em",
      fontSize: "22px",
    },
  },
  inputLabel: {
    universal: {
      color: Colours.gold,
      fontFamily: "'IBM Plex Mono', Menlo, monospace",
      fontSize: "20px",
    },
  },
  submitButtonContainer: {
    universal: {
      display: "flex",
      alignItems: "flex-end",
    },
  },
  submitButton: {
    universal: {
      appearance: "none",
      display: "inline-block",
      textAlign: "center",
      textDecoration: "none",
      color: Colours.white,
      background: "transparent",
      border: "2px solid " + Colours.white,
      borderRadius: "100px",
      padding: "0.5em 1em",
      fontSize: "24px",
      fontWeight: "500",
      fontFamily: "'IBM Plex Mono', Menlo, monospace",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      width: "100%",
      maxHeight: "60px",
    },
  },
};

export const ActionNetworkFooterForm: ViewElement<{
  popup?: boolean;
  finishFunction?: () => void;
}> = ({ popup = false, finishFunction }) => {
  const [requestError, setRequestError] = useState<String>();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<String>();

  if (loading) {
    return <div style={{ margin: "auto" }}></div>;
  } else {
    return (
      <EmailStepForm
        setLoading={setLoading}
        setEmail={setEmail}
        setRequestError={setRequestError}
        requestError={requestError}
        popup={popup}
        finishFunction={finishFunction}
      />
    );
  }
};

const EmailStepForm = ({
  setLoading,
  setEmail,
  setRequestError,
  requestError,
  popup,
  finishFunction,
}) => {
  const emailStepFields = {
    ...emailFields,
    ...candleJarContactFields,
  };

  const [subscribeHovered, setSubscribeHovered] = useState(false);

  const { screenSize } = useContext(ScreenSizeContext);

  const schema = yup.object({ ...emailStepFields });

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
  });

  const inputProps = { register, schema, errors, watch };

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const { EMAIL, candle_jar_contact } = data;
    const asBriefing = true;

    const response = await fetch(`/.netlify/functions/signupUser`, {
      method: "POST",
      body: JSON.stringify({
        merge_fields: {
          EMAIL,
          candle_jar_contact,
        },
        asBriefing,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    // check response; show general error if not ok
    if (!response.ok) {
      try {
        const json = await response.json();
        setRequestError(json.errorMessage);
      } catch (err) {
        setRequestError(
          "There was an error processing your request. Please, try again."
        );
      }
      setLoading(false);
    } else {
      // upon correct response, empty errors, empty input value, track event
      // and navigate to "subscriber" page
      setEmail(EMAIL);
      setRequestError("");
      setValue("EMAIL", "");
      setLoading(false);
      if (finishFunction) {
        finishFunction();
      }
      // navigate(`/subscriber?EMAIL=${data.EMAIL}`)
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={responsiveStyling(styles.emailStepGrid, screenSize)}>
        <div>
          <TextInput
            inputStyle={responsiveStyling(styles.textInput, screenSize)}
            labelStyle={responsiveStyling(styles.inputLabel, screenSize)}
            showErrors={true}
            showLabel={true}
            label="Email"
            name="EMAIL"
            type="email"
            {...inputProps}
          />
        </div>
        <div
          style={responsiveStyling(styles.submitButtonContainer, screenSize)}
        >
          <input
            style={{
              ...responsiveStyling(styles.submitButton, screenSize),
              ...{
                color: subscribeHovered ? Colours.gold : Colours.white,
                borderColor: subscribeHovered ? Colours.gold : Colours.white,
              },
            }}
            type="submit"
            value="Subscribe"
            name="SUBSCRIBE"
            onMouseEnter={() => setSubscribeHovered(true)}
            onMouseLeave={() => setSubscribeHovered(false)}
          />
        </div>
        {/*(errors.EMAIL || requestError) && (
          <div
            sx={{ gridColumn: "span 2", fontSize: [2, 3, 4], color: "white" }}
          >
            {(errors.EMAIL && "Please, enter a valid e-mail address.") ||
              requestError}
          </div>
        )*/}
      </div>

      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        style={{
          position: "absolute",
          left: -5000,
          display: "none !important",
        }}
        aria-hidden="true"
      >
        <input
          type="text"
          tabIndex={-1}
          autoComplete="off"
          defaultValue=""
          {...register("candle_jar_contact")}
        />
      </div>
    </form>
  );
};

const InfoStepForm = ({
  setLoading,
  setRequestError,
  email,
  requestError,
  nextStep,
  analytics,
}) => {
  const { screenSize } = useContext(ScreenSizeContext);

  const filteredLanguages = languages.filter((l) =>
    ["en", "es", "de", "fr", "pt-br"].includes(l.isoName)
  );

  const infoStepFields = {
    ...personalFields,
    ...langFields(filteredLanguages.map((fl) => fl.isoName)),
    ...candleJarContactFields,
  };

  const schema = yup.object({ ...infoStepFields });

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
  });

  const inputProps = { register, schema, errors, watch };

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const { LANGUAGE, FNAME, LNAME, candle_jar_contact } = data;
    const asBriefing = true;

    const EMAIL = email;

    const testResponse = await fetch(`/.netlify/functions/signupUser`, {
      method: "POST",
      body: JSON.stringify({
        test1: "test!!!",
      }),
    });

    //   const response = await fetch(`/.netlify/functions/signupUser`, {
    //     method: "POST",
    //     body: JSON.stringify({
    //       merge_fields: {
    //         EMAIL,
    //         LANGUAGE,
    //         FNAME,
    //         LNAME,
    //         candle_jar_contact,
    //       },
    //       asBriefing,
    //     }),
    //     headers: {
    //       "Content-Type": "application/json; charset=utf-8",
    //     },
    //   });

    //   // check response; show general error if not ok
    //   if (!response.ok) {
    //     try {
    //       const json = await response.json();
    //       setRequestError(json.errorMessage);
    //     } catch (err) {
    //       setRequestError(
    //         "There was an error processing your request. Please, try again."
    //       );
    //     }
    //     setLoading(false);
    //   } else {
    //     // upon correct response, empty errors, empty input value, track event
    //     // and navigate to "subscriber" page
    //     setRequestError("");
    //     //analytics.trackEvent({
    //     //  category: "engagement",
    //     //  action: "subscribe",
    //     //})
    //     setLoading(false);
    //     nextStep();
    //     // navigate(`/subscriber?EMAIL=${data.EMAIL}`)
    //   }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={responsiveStyling(styles.emailStepGrid, screenSize)}>
        <div>
          <SelectInput
            name="LANGUAGE"
            {...inputProps}
            options={filteredLanguages}
            renderOption={(l) => (
              <option key={l.isoName} value={l.isoName}>
                {l.name} / {l.nativeName}
              </option>
            )}
          />

          <TextInput
            showLabel={false}
            showErrors={false}
            name="FNAME"
            {...inputProps}
          />

          <TextInput
            showLabel={false}
            showErrors={false}
            name="LNAME"
            {...inputProps}
          />
        </div>
        <div>
          <input type="submit" value="subscribe" name="SUBSCRIBE" />
        </div>
        {requestError && <div>{requestError}</div>}
      </div>

      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        sx={{ position: "absolute", left: -5000, display: "none !important" }}
        aria-hidden="true"
      >
        <input
          type="text"
          name="candle_jar_contact"
          tabIndex={-1}
          autoComplete="off"
          defaultValue=""
          ref={register()}
        />
      </div>
    </form>
  );
};

const InterestsStepForm = ({
  setLoading,
  setRequestError,
  email,
  requestError,
  nextStep,
  analytics,
}) => {
  const { screenSize } = useContext(ScreenSizeContext);

  const useInterestTags = () => {
    const res = useStaticQuery(INTEREST_TAG_QUERY);
    //console.log("res: ", res);
    const edges = res.allMarkdownRemark.edges.map(
      (e) => e.node
    ) as NodeContent[];
    return edges.sort(compareInterestTags);
  };

  const interestTags = useInterestTags();

  const interestsStepFields = {
    ...interestTagFields,
    ...candleJarContactFields,
  };

  const schema = yup.object({ ...interestsStepFields });

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
  });

  const inputProps = { register, schema, errors, watch };

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const { INTEREST_TAGS, candle_jar_contact } = data;
    const asBriefing = true;

    const EMAIL = email;

    const INTEREST_TAG_STRINGS = INTEREST_TAGS.map((t, index) => {
      if (t) {
        return interestTags[index].frontmatter.title;
      }
    }).filter((t) => t);

    const response = await fetch(`/.netlify/functions/signupUser`, {
      method: "POST",
      body: JSON.stringify({
        merge_fields: {
          EMAIL,
          INTEREST_TAG_STRINGS,
          candle_jar_contact,
        },
        asBriefing,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    // check response; show general error if not ok
    if (!response.ok) {
      try {
        const json = await response.json();
        setRequestError(json.errorMessage);
      } catch (err) {
        setRequestError(
          "There was an error processing your request. Please, try again."
        );
      }
      setLoading(false);
    } else {
      // upon correct response, empty errors, empty input value, track event
      // and navigate to "subscriber" page
      setRequestError("");
      //analytics.trackEvent({
      //  category: "engagement",
      //  action: "subscribe",
      //})
      setLoading(false);
      nextStep();
      navigate(`/subscriber?EMAIL=${email}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={responsiveStyling(styles.emailStepGrid, screenSize)}>
        <div>
          <div>
            <Trans>What interests you most?</Trans>
          </div>
          <CheckboxInputArray
            labels={interestTags.map((t) => useLocalisedContent(t).title)}
            name={"INTEREST_TAGS"}
            {...inputProps}
          />
        </div>
        <div>
          <input type="submit" value="subscribe" name="SUBSCRIBE" />
        </div>
        {(errors.LANGUAGE || errors.FNAME || errors.LNAME || requestError) && (
          <div>
            {((errors.LANGUAGE || errors.FNAME || errors.LNAME) &&
              "There was an error.") ||
              requestError}
          </div>
        )}
      </div>

      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        style={{
          position: "absolute",
          left: -5000,
          display: "none !important",
        }}
        aria-hidden="true"
      >
        <input
          type="text"
          name="candle_jar_contact"
          tabIndex={-1}
          autoComplete="off"
          defaultValue=""
          ref={register()}
        />
      </div>
    </form>
  );
};

const compareInterestTags = (a, b) => {
  if (a.frontmatter.title < b.frontmatter.title) {
    return -1;
  } else if (a.frontmatter.title > b.frontmatter.title) {
    return 1;
  }
  return 0;
};

const INTEREST_TAG_QUERY = graphql`
  query InterestTagsQuery {
    allMarkdownRemark(filter: { fields: { type: { eq: "interest-tags" } } }) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            translations {
              childMarkdownRemark {
                frontmatter {
                  title
                  locale
                }
              }
            }
          }
        }
      }
    }
  }
`;
